import expiredPayoutTypes from "../constants/expiredPayoutTypes";

const INITIAL_STATE = {
  list: [],
  page: {},
};

const expiredPayoutReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case expiredPayoutTypes.SET_EXPIRED_PAYOUTS:
      return {
        ...state,
        list: action.payload,
      };
    default:
      return state;
  }
};

export default expiredPayoutReducer;
