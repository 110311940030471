import PENALTY_AMOUNT_TYPES from "../constants/penaltAmountTypes";

const INITIAL_STATE = {
  penaltyAmountHistory: [],
};

const penaltyAmountReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PENALTY_AMOUNT_TYPES.SET_PENALTY_AMOUNT_HISTORY:
      return { ...state, penaltyAmountHistory: action.payload };
    default:
      return state;
  }
};

export default penaltyAmountReducer;
