export enum Task {
  JunkRemoval = "junk-removal",
  CardboardRemoval = "cardboard-removal",
  DumpsterRental = "dumpster-rental",
  PowerWashing = "power-washing",
  AlcoholDisposal = "alcohol-disposal",
  BeverageDisposal = "beverage-disposal",
  CompostDisposal = "compost-disposal",
  HazardousWasteDisposal = "hazardous-waste-disposal",
  SanitizerDisposal = "sanitizer-disposal",
  SolarPanelDisposal = "solar-panel-disposal",
  FireDebrisDisposal = "fire-debris-disposal",
}

export enum Vehicle {
  SUV = "SUV",
  Truck = "truck",
  PickupTruck = "pick-up-truck"
}
